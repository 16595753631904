var PubSub = require('pubsub-js');

const constants = {
    HELP_SECTION_MOUNTED: "HELP_SECTION_MOUNTED"
}

var pub_function = function (event) {
    return function (data) {
        return PubSub.publish(event, data);
    }
}

var sub_function = function (event) {
    return function (callback) {
        return PubSub.subscribe(event, callback);
    }
}


var publish = {
    help_section_mounted: pub_function(constants.HELP_SECTION_MOUNTED)
}

var subscribe = {
    help_section_mounted: sub_function(constants.HELP_SECTION_MOUNTED)

}

module.exports = {
    publish: publish,
    subscribe: subscribe,
}
