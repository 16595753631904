/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ContactComponentView from './ContactComponentView'
import NavComponentView from './NavComponentView'
import HelpComponentView from './HelpComponentView'
import FooterComponentView from './FooterComponentView'
import MobileFooterView from './MobileFooterView'
import FooterContainerSmView from './FooterContainerSmView'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=644ad0ca9dae77a391ba11c2").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class FolioView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/FolioController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = FolioView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '644ad8aa5bba886c22d0b763'
    htmlEl.dataset['wfSite'] = '644ad0ca9dae77a391ba11c2'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = FolioView.Controller !== FolioView ? transformProxies(this.props.children) : {
      'hero-folio-button': [],
      'header-contact-link': [],
      'package-quote-button': [],
      'pricing-button': [],
      'pricing-contact-link': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-testing.webflow.css);

          @media (min-width:992px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"48a5ceb2-f462-e915-2ea6-cfd4be0c8150\"] {opacity:0;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"48a5ceb2-f462-e915-2ea6-cfd4be0c8144\"] {opacity:0;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"88435d74-c3e5-edcd-fa84-cf2267841b05\"] {opacity:0;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"88435d74-c3e5-edcd-fa84-cf2267841b02\"] {opacity:0;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"88435d74-c3e5-edcd-fa84-cf2267841b03\"] {opacity:0;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"88435d74-c3e5-edcd-fa84-cf2267841b07\"] {opacity:0;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"88435d74-c3e5-edcd-fa84-cf2267841b09\"] {opacity:0;}}@media (max-width:991px) and (min-width:768px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"48a5ceb2-f462-e915-2ea6-cfd4be0c8150\"] {opacity:0;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"48a5ceb2-f462-e915-2ea6-cfd4be0c8144\"] {opacity:0;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"88435d74-c3e5-edcd-fa84-cf2267841b05\"] {opacity:0;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"88435d74-c3e5-edcd-fa84-cf2267841b02\"] {opacity:0;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"88435d74-c3e5-edcd-fa84-cf2267841b03\"] {opacity:0;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"88435d74-c3e5-edcd-fa84-cf2267841b07\"] {opacity:0;}html.w-mod-js:not(.w-mod-ix) [data-w-id=\"88435d74-c3e5-edcd-fa84-cf2267841b09\"] {opacity:0;}}
        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <ContactComponentView.Controller />
            <NavComponentView.Controller />
            <div className="af-class-folio-hero">
              <div className="af-class-small-container w-container">
                <div className="af-class-columns-2 w-row">
                  <div className="af-class-column-81 w-col w-col-6">
                    <div className="af-class-upper-case-label">FOLIO</div>
                    <div>
                      <div data-w-id="48a5ceb2-f462-e915-2ea6-cfd4be0c8140" style={{opacity: 0}} className="af-class-h2 af-class-header af-class-folio">Activate learning with content you can trust.</div>
                      <p className="af-class-paragraph-4 af-class-slide">Folio is a library of hundreds of lesson-supporting posters, activities, and printables for teachers, parents, and students. In English and Spanish.</p>
                    </div>
                    <div data-w-id="48a5ceb2-f462-e915-2ea6-cfd4be0c8144" className="af-class-div-block-61">
                      {map(proxies['hero-folio-button'], props => <a href="#" {...{...props, className: `af-class-blue-button af-class-header w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Get Folio</React.Fragment>}</a>)}
                    </div>
                    <div className="af-class-text-block-91"><span className="af-class-caption">Interested in Folio for your school? </span>
                      <a href="#" className="af-class-h6_link">{map(proxies['header-contact-link'], props => <span {...{...props, className: `af-class-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Contact us.</React.Fragment>}</span>)}</a>
                    </div>
                  </div>
                  <div data-w-id="48a5ceb2-f462-e915-2ea6-cfd4be0c814f" style={{opacity: 0}} className="af-class-column-76 w-col w-col-6"><img src="images/folio-header.png" loading="lazy" width={699} data-w-id="48a5ceb2-f462-e915-2ea6-cfd4be0c8150" alt srcSet="images/folio-header-p-500.png 500w, images/folio-header-p-800.png 800w, images/folio-header-p-1080.png 1080w, images/folio-header-p-1600.png 1600w, images/folio-header.png 1612w" sizes="(max-width: 479px) 93vw, (max-width: 767px) 92vw, (max-width: 991px) 561px, 699px" className="af-class-image-12" /></div>
                </div>
              </div>
            </div>
            <div className="af-class-section-8">
              <div className="af-class-container-small w-container">
                <div className="af-class-main-column af-class-inverse w-row">
                  <div data-w-id="88435d74-c3e5-edcd-fa84-cf2267841b02" className="af-class-column-87 w-col w-col-7"><img src="images/pss_folio_img_geometry_03.png" loading="lazy" width={625} data-w-id="88435d74-c3e5-edcd-fa84-cf2267841b03" alt srcSet="images/pss_folio_img_geometry_03-p-500.png 500w, images/pss_folio_img_geometry_03-p-800.png 800w, images/pss_folio_img_geometry_03.png 1251w" sizes="(max-width: 479px) 100vw, (max-width: 767px) 53vw, (max-width: 991px) 416.328125px, 540px" className="af-class-image-13" /></div>
                  <div className="af-class-column-70 w-col w-col-5">
                    <div data-w-id="88435d74-c3e5-edcd-fa84-cf2267841b05" className="af-class-div-block-75">
                      <div>
                        <div data-w-id="88435d74-c3e5-edcd-fa84-cf2267841b07" className="af-class-upper-case-label">HIGH QUALITY CONTENT</div>
                        <h1 data-w-id="88435d74-c3e5-edcd-fa84-cf2267841b09" className="af-class-h5 af-class-bold">Make connections that last. Every Lesson. ✨</h1>
                      </div>
                      <p className="af-class-paragraph-4 af-class-slide">Folio optimizes learning time with memorable visuals, engages students using disciplined design principles, and minimizes lesson prep time for teachers and parents.<br /></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-padding-vertical af-class-padding-xxlarge" />
            </div>
            <div className="af-class-section-20">
              <div className="af-class-container-small w-container">
                <div className="af-class-main-column w-row">
                  <div className="af-class-column-104 w-col w-col-5">
                    <div>
                      <div>
                        <div className="af-class-upper-case-label">Expertise and Creativity</div>
                        <h1 className="af-class-h5 af-class-bold af-class-animate-cta-header"><strong className="af-class-h5 af-class-bold">Teachers + Designers collaborating on each resource.</strong></h1>
                      </div>
                      <div className="af-class-div-block-69">
                        <p className="af-class-paragraph-4 af-class-slide">The Folio team of experienced teachers and graphic designers combines sound teaching principles with design thinking to create engaging resources that optimize the learning process<br /></p>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-column-88 w-col w-col-7"><img className="af-class-img_butterflyresource" src="images/monitor.png" width={800} alt style={{opacity: 0}} sizes="(max-width: 479px) 100vw, (max-width: 767px) 53vw, (max-width: 991px) 416.3359375px, 540px" data-w-id="aba0aa6d-4971-dd0e-c0b2-6221ea4e38b5" loading="lazy" srcSet="images/monitor-p-500.png 500w, images/monitor.png 783w" /></div>
                </div>
              </div>
              <div className="af-class-padding-vertical af-class-padding-xxlarge" />
            </div>
            <div className="af-class-section-20">
              <div className="af-class-container-small w-container">
                <div className="af-class-main-column af-class-inverse w-row">
                  <div className="af-class-column-89 w-col w-col-7"><img className="af-class-img_butterflyresource" src="images/pss_folio_img_butterfly_02.png" width="625.5" alt style={{opacity: 0}} sizes="(max-width: 479px) 100vw, (max-width: 767px) 53vw, (max-width: 991px) 416.328125px, 540px" data-w-id="6f18903c-2de5-4b1f-b802-23f48770e486" loading="lazy" srcSet="images/pss_folio_img_butterfly_02-p-500.png 500w, images/pss_folio_img_butterfly_02-p-800.png 800w, images/pss_folio_img_butterfly_02.png 1254w" /></div>
                  <div className="af-class-column-90 w-col w-col-5">
                    <div>
                      <div className="af-class-upper-case-label">SPANISH CONTENT</div>
                      <div>
                        <h1 className="af-class-h5 af-class-bold af-class-animate-cta-header">Engage ELLs and their parents with hundreds of Spanish Language Resources.✨</h1>
                      </div>
                    </div>
                    <div className="af-class-div-block-70">
                      <p className="af-class-paragraph-4 af-class-slide">There are over 5 million English language learners, including 3 million in US schools. There are NO high-quality resources to support our Spanish speaking learners. Folio is committed to creating content to help teachers reach all learners.<br /><br /></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-padding-vertical af-class-padding-xxlarge" />
            </div>
            <div className="af-class-connectionfeaturesection_gradient">
              <div className="af-class-container-small w-container">
                <div className="af-class-upper-case-label af-class-center">Complete Learning PackagE</div>
                <div className="af-class-h5 af-class-bold af-class-center af-class-animate-cta-header">Folio is the perfect companion for the poster maker.</div>
                <div className="af-class-columns-8 w-row">
                  <div className="af-class-column-84 w-col w-col-6">
                    <div className="af-class-div-block-64">
                      <div className="af-class-div-block-64"><img className="af-class-image-19" src="images/folio-resource.png" width={431} alt style={{opacity: 0}} sizes="(max-width: 479px) 77vw, (max-width: 767px) 56vw, (max-width: 991px) 374.0078125px, 431px" data-w-id="cb862105-7073-1368-bbfa-e4efb7577451" loading="lazy" srcSet="images/folio-resource-p-500.png 500w, images/folio-resource-p-800.png 800w, images/folio-resource-p-1080.png 1080w, images/folio-resource.png 1282w" />
                        <div className="af-class-paragraph-4 af-class-slide af-class-center">Access to hundreds of templates, posters, and engaging resources!</div>
                      </div>
                    </div>
                  </div>
                  <div className="w-col w-col-1">
                    <div className="af-class-div-block-58">
                      <div className="af-class-text-block-96">+</div>
                    </div>
                  </div>
                  <div className="af-class-column-83 w-col w-col-5">
                    <div className="af-class-div-block-64"><img src="https://uploads-ssl.webflow.com/63dabbb2d238ed7213920cfb/64414b39e98640b67c6577a3_PosterMakerpsslogo.png" loading="lazy" width={294} style={{opacity: 0}} alt data-w-id="cb862105-7073-1368-bbfa-e4efb757745a" className="af-class-image-19" />
                      <div className="af-class-paragraph-4 af-class-slide af-class-center">Transform your classrooms and hallways with the power of in-house printing!</div>
                    </div>
                  </div>
                </div>
                {map(proxies['package-quote-button'], props => <a href="#" {...{...props, className: `af-class-blue-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Get Bundle Now! </React.Fragment>}</a>)}
              </div>
            </div>
            <div className="af-class-section-18">
              <div className="af-class-container-small w-container">
                <div className="af-class-upper-case-label af-class-center">Get started</div>
                <div className="af-class-h5 af-class-bold af-class-center af-class-animate-cta-headers"><strong>Get Folio access for your entire school today</strong><br /></div>
                <div id="w-node-_0d4daa7f-13c4-7ff3-5baf-dec84d458738-22d0b763" data-w-id="0d4daa7f-13c4-7ff3-5baf-dec84d458738" style={{opacity: 0}} className="af-class-card af-class-folio-price">
                  <div className="af-class-upper-case-label">Folio for education</div>
                  <div className="af-class-card-wrap">
                    <h3 className="af-class-mb-40">$490/yr</h3>
                    <p className="af-class-p4 af-class-logins">Whole School Access</p>
                    <p className="af-class-paragraph-4">Get all of your educators access to Folio with individual logins and access to our teacher training webinars!</p>
                  </div>
                  <div className="af-class-card-bot">
                    {map(proxies['pricing-button'], props => <a data-ms-modal="signup" href="#" {...{...props, className: `af-class-button-wrap w-inline-block ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>
                      <p className="af-class-get-started">Get Started</p><img src="images/arrow-up-right.svg" loading="lazy" alt className="af-class-button-img" />
                    </React.Fragment>}</a>)}
                  </div>
                </div>
                {map(proxies['pricing-contact-link'], props => <div {...{...props, className: `af-class-p4 af-class-underline ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Contact Us here for District Pricing options!</React.Fragment>}</div>)}
              </div>
            </div>
            <div className="af-class-video_div">
              <div className="af-class-container-19 w-container">
                <div className="w-row">
                  <div className="af-class-column-6 w-col w-col-4">
                    <h1 className="af-class-h3_book_white">Ready-to-use learning resources</h1>
                    <div data-w-id="bf6b71ca-2b9f-b50f-c4ad-c22b19d327ee" style={{opacity: 0}} className="af-class-quote">"Teachers value the support that visuals lend to classroom instruction because they encourage students to make associations between pieces of information, soak up chunks of course content quickly, and function as a memory aid.” <br /><br />–Jennifer Davis Bowman<br /><span className="af-class-text-span">Source: </span>
                      <a href="https://www.edutopia.org/article/making-most-visual-aids" target="_blank" className="af-class-h6_link_white"><span className="af-class-text-span">Making the Most of Visual Aids, Edutopia</span></a>
                    </div>
                    <a href="#" className="af-class-button_cta_white w-button">Read Article</a>
                  </div>
                  <div className="af-class-videocolumn w-col w-col-8">
                    <div style={{paddingTop: '56.27659574468085%'}} className="w-video w-embed"><iframe className="af-class-embedly-embed" src="https://cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fplayer.vimeo.com%2Fvideo%2F667964045%3Fh%3D1cd5f36458%26app_id%3D122963&dntp=1&display_name=Vimeo&url=https%3A%2F%2Fvimeo.com%2F667964045&image=https%3A%2F%2Fi.vimeocdn.com%2Fvideo%2F1352394759-60b02595476edbf0e26c7d44c7817ff50d3925221803b2a5e77d5b5f92a60e9e-d_1280&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=vimeo" scrolling="no" title="Introducing Folio" /></div>
                  </div>
                </div>
              </div>
            </div>
            <HelpComponentView.Controller />
            <FooterComponentView.Controller />
            <MobileFooterView.Controller />
            <FooterContainerSmView.Controller />
          </div>
        </span>
      </span>
    )
  }
}

export default FolioView

/* eslint-enable */