import React from "react";
import MobileFooterView from "../views/MobileFooterView";

class MobileFooterController extends React.Component{
    state = {}
    render(){
        return(
        <MobileFooterView>
            <logo-button onClick={()=>{this.handleLink("")}}/>
            <phone-link onClick={()=>{this.handleHREF("tel:+18004859722")}}/>
            <email-link onClick={()=>{this.handleHREF("mailto:support@pssk12.com")}}>support@pssk12.com</email-link>
            <pm-link onClick={()=>{this.handleLink("/poster-maker")}}/>
            <folio-link onClick={()=>{this.handleHREF("/folio")}}/>
            <shop-link onClick={()=>{this.handleExternalLink("https://shop.pssk12.com")}}/>
            <help-link onClick={()=>{this.handleLink("/help")}}/>
            <quote-link onClick={()=>{this.handleExternalLink("https://quotes.pssk12.com/create-quote")}}/>
            <contact-link onClick={()=>{this.handleLink("/contact-us")}}/>
            <social-link onClick={()=>{this.handleExternalLink("https://linktr.ee/foliok12")}}/>
        </MobileFooterView>)
    }

    handleLink(path){
        window.location.pathname = path
    }

    handleExternalLink(path){
        window.open(path, "_blank");
    }

    handleHREF(path){
        window.location.href=path;
    }

}
export default MobileFooterController