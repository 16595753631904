import React from "react";
import PosterMakerView from "../views/PosterMakerView";

class PosterMakerController extends React.Component{
    state = {}
    render(){
        return(
        <PosterMakerView>
            <hero-quote-button onClick={()=>{
                window.open("https://quotes.pssk12.com/create-quote", "_blank");
            }}/>
            <package-quote-button onClick={()=>{
                window.open("https://quotes.pssk12.com/create-quote", "_blank");
            }}/>
            <package-contact-link onClick={()=>{
                window.location.href="contact-us";
            }}/>
            <pricing-quote-button onClick={()=>{
                window.open("https://quotes.pssk12.com/create-quote", "_blank");
            }}/>
            <pricing-contact-link onClick={()=>{
                window.location.href="/contact-us";
            }}/>
            <folio-button onClick={()=>{
                window.open("https://www.instagram.com/pssbulletin/", "_blank");
            }}/>
        </PosterMakerView>)
    }
}
export default PosterMakerController