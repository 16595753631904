import React from "react";
import FooterComponentView from "../views/FooterComponentView";

class FooterComponentController extends React.Component{
    render(){
        return(
        <FooterComponentView>
            <logo-button onClick={()=>{this.handleLink("")}}/>
            <phone-link onClick={()=>{this.handleHREF("tel:+18004859722")}}/>
            <email-link onClick={()=>{this.handleHREF("mailto:support@pssk12.com")}}/>
            <pm-link onClick={()=>{this.handleLink("/poster-maker")}}/>
            <folio-link onClick={()=>{this.handleLink("/folio")}}/>
            <shop-link onClick={()=>{this.handleExternalLink("https://shop.pssk12.com")}}/>
            <help-link onClick={()=>{this.handleLink("/help")}}/>
            <quote-link onClick={()=>{this.handleExternalLink("https://quotes.pssk12.com/create-quote")}}/>
            <contact-link onClick={()=>{this.handleLink("/contact-us")}}/>
            <yt-link onClick={()=>{this.handleExternalLink("https://www.youtube.com/channel/UCsF0wvE5345uRzW1dJqJTdw")}}/>
            <fb-link onClick={()=>{this.handleExternalLink("https://www.facebook.com/ThePSSBulletin/")}}/>
            <li-link onClick={()=>{this.handleExternalLink("https://www.linkedin.com/company/pssbulletin/")}}/>
            <ig-link onClick={()=>{this.handleExternalLink("https://www.instagram.com/pssbulletin/")}}/>
        </FooterComponentView>)
    }

    handleLink(path){
        window.location.pathname = path
    }

    handleExternalLink(path){
        window.open(path, "_blank");
    }

    handleHREF(path){
        window.location.href=path;
    }
}
export default FooterComponentController