import React from 'react';
import { useLoading } from '../tools/LoadingContext';

const LoadingAnimation = () =>{
    const { isLoading } = useLoading();

    return (
        <div id="loading_overlay" className="loading-overlay" style={{display: isLoading? "flex":"none"}}>
            <img className="loading-animation" src="/img/loading_animation.gif" alt="Loading some good stuff!" />
        </div>
    );
}

export default LoadingAnimation;