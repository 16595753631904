import React, {Component,} from 'react'
import {Routes, Route, Suspense} from 'react-router-dom'

import IndexController from './controllers/IndexController'
import HelpController from './controllers/HelpController'
import PosterMakerController from './controllers/PosterMakerController';
import FolioController from './controllers/FolioController';
import ContactUsController from './controllers/ContactUsController';
import SuppliesQuoteController from './controllers/SuppliesQuoteController';
import HelpSection from './help/HelpSection';

import { LoadingProvider } from './tools/LoadingContext';
import LoadingAnimation from './components/LoadingAnimation'

import './css/_base.scss';
import './css/webflow-fixes.scss';
import './styles';
import './scripts';

class App extends Component{
    render(){
      return(
        <LoadingProvider>
          <LoadingAnimation/>
          <Routes>
              <Route rel="canonical" path='/' element={<IndexController/>}/>
              <Route rel="canonical" path='/poster-maker' element={<PosterMakerController/>}/>
              <Route rel="canonical" path='/folio' element={<FolioController/>}/>
              <Route rel="canonical" path ="/help/*" element={<HelpSection/>}/>
              <Route rel="canonical" path='/help' element={<HelpController/>}/>
              <Route rel="canonical" path='/contact-us' element={<ContactUsController/>}/>
              <Route rel="canonical" path='/supplies-quote' element={<SuppliesQuoteController/>}/>
            
          
          </Routes>
        </LoadingProvider>
      );
    }

    componentDidMount(){
      window.scrollTo(window.scrollX, window.scrollY + 1);    
    }
}

export default App;
