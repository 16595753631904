import React from "react";
import LogosContainerView from "../views/LogosContainerView";

class LogosContainerController extends React.Component{
    state = {}
    render(){
        return(
            <LogosContainerView/>
        )
    }

    componentDidMount(){
       // this.scrollAndRemoveElement();
       /*
        const scrollingElement = document.getElementsByClassName('af-class-logo_component-slider')[0];  
        const containerWidth = scrollingElement.offsetWidth;

        scrollingElement.scrollLeft += 200;
        */
    }

    scrollAndRemoveElement() {
        const scrollingElement = document.getElementsByClassName('animation-logo-container')[0];
        const scrollingElementWidth = scrollingElement.scrollWidth;

        const animationDuration = scrollingElementWidth / 100;

        scrollingElement.style.animationDuration = `${animationDuration}s`;
    }
}
export default LogosContainerController