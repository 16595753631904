import React from "react";
import NavComponentView from "../views/NavComponentView";

class NavComponentController extends React.Component{
    state = {}
    render(){
        return(
        <NavComponentView>
            <logo-button href="/"/>
            <pm-button href="/poster-maker"/>
            <folio-button href="/folio"/>
            <quote-button href="https://quotes.pssk12.com/create-quote" target="_blank"/>
            <shop-button href="https://shop.pssk12.com" target="_blank"/>
            <help-button href="/help"/>
        </NavComponentView>)
    }
}
export default NavComponentController