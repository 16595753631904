import React from "react";
import HelpComponentView from "../views/HelpComponentView";

class HelpComponentController extends React.Component{
    state = {}
    render(){
        return(
        <HelpComponentView>
            <quote-button onClick={()=>{window.open("https://quotes.pssk12.com","_blank")}}/>
            <contact-button href="/contact-us"/>
        </HelpComponentView>)
    }

}
export default HelpComponentController