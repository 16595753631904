import React, { Component, lazy } from 'react';
import HelpSidebar from '../help/HelpSidebar';
import ContactComponentView from '../views/ContactComponentView'
import NavComponentView from '../views/NavComponentView'
import '../css/help.scss';
const PM = lazy(() => import('../help/PosterMaker2Guide'))
const MC = lazy(() => import('../help/MagicCutterGuide'));
const CL = lazy(() => import('../help/CoolLamGuide'));


class HelpSection extends Component {
    render() {
        return(
            <>
                <ContactComponentView.Controller />
                <NavComponentView.Controller />
                <div id="help_section" className="container help-section">
                    <HelpSidebar/>
                    {this.helpPage()}
                </div>
            </>
        )
    }

    helpPage() {
        const path = window.location.pathname;
        if(path === '/help/poster_maker_2.0' ){
            return <PM/>
        }
        else if(path === '/help/magic_cutter'){
            return <MC/>
        }
        else if(path === '/help/cool_lam'){
            return <CL/>
        }
        else{
         window.location.href = '/help/poster_maker_2.0';
        }
    }
}

export default HelpSection;