import React, { Component } from 'react';
import '../css/sidebar.scss';
import PubSub from '../pubsub'

var lastScrollPosition = 0;
const helpSections = [
    {
        section: "Poster Maker 2.0",
        topics: ["Setup", "Quick Start", "Poster Artist", "Creating and Editing Posters", "Printing a Banner", "Tile Printing", "Other Media Types and Setups", "Printing PDF Files", "Importing Custom Graphics", "Troubleshooting"]
    },
    {
        section: "Magic Cutter",
        topics: ["Setup", "Quick Start", "The Workspace", "The Library", "Creating, Loading, and Saving Projects", "Shape Cutting Without a Printout", "Other Media Types and Setups", "Adjusting the Blade Length", "Setting the Condition", "Fonts and Offset Merging", "Importing Custom Graphics", "Troubleshooting"]
    },
    {
        section: "Cool Lam",
        topics: ["Setup", "Quick Start", "Loading Supply Rolls", "Basic Operation", "Supply Roll Removal", "Troubleshooting"]
    }
]


class Help extends Component {
    constructor(){
        super();

        this.state = {
            selectedIndex: 0
        }

    }
    render() {
        var sidebarItems = helpSections.map((section) => {
            var sectionTopics = section.topics.map((topic, index) => {
                var tempHref = "/help/" + section.section + "/#" + topic;
                tempHref = tempHref.replaceAll(" ", "_").toLowerCase()
                var tempKey = section.section + "_" + topic;
                tempKey=tempKey.replaceAll(" ", "_").toLowerCase();
                return (
                    <li key={tempKey} className={(this.state.selectedIndex === index) ? "active" : ""}><a id={"sidebar_" + tempKey} className={(this.state.selectedIndex === index) ? "p active" : "p"} href={tempHref} onClick={(e)=>{
                        var hash = e.target.hash.substring(1);
                        e.preventDefault();
                        try{
                            document.getElementById('help_content').scrollTop = document.getElementById(hash).offsetTop;
                        }
                        catch{
                            return false;
                        }
                    }}>{topic}</a></li>
                )
            })

            return (
                <div key={"section_" + section.section}>
                    <button id={section.section.replaceAll(" ", "_").toLowerCase()} className="sidebar-section">
                        <div className="sidebar-arrow"></div>
                        <p>{section.section}</p>
                    </button>
                    <ul className="sidebar-topics">
                        {sectionTopics}
                    </ul>
                </div>
            )

        })

        return (
            <div className="pss-sidebar">
                {sidebarItems}
            </div>
        )
    }

    componentDidMount() {
        var products = document.getElementsByClassName("sidebar-section");

        var currentSection = window.location.pathname.substring(5).replaceAll("/", "");
        try{

            document.getElementById(currentSection).click();
        }
        catch{

        }
        finally{
            Array.prototype.forEach.call(products, (product) => {
                if (product.id === currentSection) {
                    product.nextElementSibling.style.display = "flex";
                    product.className = "sidebar-section selected"
                }
                else {
                    try {
                        product.nextElementSibling.style.display = "none";
                        product.className = "sidebar-section"

                    }
                    finally {
                        product.addEventListener("click", function () {
                            if (this.id !== currentSection) {
                                window.location.pathname = "help/" + this.id;
                            }
                        });
                    }
                }
            });
        } 

        PubSub.subscribe.help_section_mounted(()=>{
            var contentContainer = document.getElementById("help_content");

            contentContainer.addEventListener("scroll", (e) => {
                var headers = contentContainer.getElementsByTagName("h3");
                var value = 0
                if (lastScrollPosition < e.target.scrollTop && this.state.selectedIndex < headers.length - 1) {
                    if (this.isInViewport(headers[this.state.selectedIndex + 1]) && !this.isInViewport(headers[this.state.selectedIndex])) {
                        value = 1;
                    }
                }
                else if (lastScrollPosition > e.target.scrollTop && this.state.selectedIndex > 0) {
                    if (this.isInViewport(headers[this.state.selectedIndex - 1])) {
                        value = -1;
                    }
                }
                if (value !== 0) {
                    this.setState({
                        selectedIndex: this.state.selectedIndex + value
                    }, () => {
                        lastScrollPosition = e.target.scrollTop;
                    });
                }

            }, { passive: true })
        })
    }

    isInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)

        );
    }
}

export default Help;