import React from "react";
import HelpView from "../views/HelpView";

class HelpController extends React.Component{
    state = {}
    render(){
        return(
        <HelpView>
            <help-yt onClick={()=>{
                window.open("https://www.youtube.com/playlist?list=PLsfoT6IwfSq8P74zFFn1yCwbDnEypb6xD", "_blank")
            }}></help-yt>
            <help-pm onClick={()=>{
                window.location.pathname="help/poster_maker"
            }}></help-pm>
            <help-mc onClick={()=>{
                window.location.pathname="help/magic_cutter"
            }}></help-mc>
            <help-cl onClick={()=>{
                window.location.pathname="help/cool_lam"
            }}></help-cl>
          
        </HelpView>)
    }

}
export default HelpController