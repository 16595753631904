/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class LogosContainerView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/LogosContainerController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = LogosContainerView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    /* View has no WebFlow data attributes */

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = LogosContainerView.Controller !== LogosContainerView ? transformProxies(this.props.children) : {

    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-testing.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-logo_component-slider"><img src="images/Artboard-9.png" srcSet="images/Artboard-9-p-500.png 500w, images/Artboard-9.png 788w" width={300} sizes="(max-width: 479px) 100px, 102.3359375px" alt className="af-class-logo-slider-img" /><img src="images/svgg.png" srcSet="images/svgg-p-500.png 500w, images/svgg-p-800.png 800w, images/svgg.png 947w" width={300} sizes="(max-width: 1279px) 81.078125px, (max-width: 1439px) 6vw, 81.078125px" alt className="af-class-logo-slider-img" /><img src="images/Artboard-8.png" srcSet="images/Artboard-8-p-500.png 500w, images/Artboard-8.png 788w" width={250} sizes="(max-width: 479px) 100px, 102.3359375px" alt className="af-class-logo-slider-img" /><img src="images/Artboard-6.png" srcSet="images/Artboard-6-p-500.png 500w, images/Artboard-6.png 788w" width={300} sizes="(max-width: 479px) 100px, 102.3359375px" alt className="af-class-logo-slider-img" /><img src="images/Artboard-11.png" srcSet="images/Artboard-11-p-500.png 500w, images/Artboard-11.png 788w" width={300} sizes="(max-width: 479px) 100px, 102.3359375px" alt className="af-class-logo-slider-img" /><img src="images/Artboard-12.png" srcSet="images/Artboard-12-p-500.png 500w, images/Artboard-12.png 788w" width={300} sizes="(max-width: 479px) 100px, 102.3359375px" alt className="af-class-logo-slider-img" /><img src="images/Fairfax-County-Schools-Logo.png" alt className="af-class-logo-slider-img" /><img src="images/Artboard-10.png" srcSet="images/Artboard-10-p-500.png 500w, images/Artboard-10.png 788w" width={300} sizes="(max-width: 479px) 100px, 102.3359375px" alt className="af-class-logo-slider-img" /><img src="images/wake.png" alt width={300} className="af-class-logo-slider-img" /></div>
        </span>
      </span>
    )
  }
}

export default LogosContainerView

/* eslint-enable */