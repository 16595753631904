/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ContactComponentView from './ContactComponentView'
import FooterComponentView from './FooterComponentView'
import FooterContainerSmView from './FooterContainerSmView'
import MobileFooterView from './MobileFooterView'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=644ad0ca9dae77a391ba11c2").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class SuppliesQuoteView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/SuppliesQuoteController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = SuppliesQuoteView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '659c60501860c9419291f147'
    htmlEl.dataset['wfSite'] = '644ad0ca9dae77a391ba11c2'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = SuppliesQuoteView.Controller !== SuppliesQuoteView ? transformProxies(this.props.children) : {
      'logo-button': [],
      'quote-message': [],
      'quote-form-container': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-testing.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body-3">
            <ContactComponentView.Controller />
            <div data-animation="default" data-collapse="medium" data-duration={300} data-easing="ease" data-easing2="linear" role="banner" className="af-class-navbar-quote af-class-blank w-nav">
              <div className="af-class-nav-bar-container af-class-blank w-container">
                {map(proxies['logo-button'], props => <a href="index.html" {...{...props, className: `af-class-brand af-class-blank w-nav-brand ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/NEW-pss-logo.svg" loading="lazy" width={92} alt="PSS Logo" className="af-class-image-3" /></React.Fragment>}</a>)}
              </div>
            </div>
            <div className="af-class-section-13">
              <div className="af-class-container-28 w-container">
                <div className="af-class-upper-case-label">Get an Instant Quote!</div>
                {map(proxies['quote-message'], props => <div {...props}>{props.children}</div>)}
              </div>
              <div className="w-layout-blockcontainer af-class-container-34 w-container">
                {map(proxies['quote-form-container'], props => <div {...{...props, className: `af-class-div-block-91 ${props.className || ''}`}}>{props.children}</div>)}
              </div>
            </div>
            <FooterComponentView.Controller />
            <FooterContainerSmView.Controller />
            <MobileFooterView.Controller />
          </div>
        </span>
      </span>
    )
  }
}

export default SuppliesQuoteView

/* eslint-enable */