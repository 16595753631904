/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class NavComponentView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/NavComponentController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = NavComponentView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    /* View has no WebFlow data attributes */

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = NavComponentView.Controller !== NavComponentView ? transformProxies(this.props.children) : {
      'logo-button': [],
      'pm-button': [],
      'folio-button': [],
      'help-button': [],
      'quote-button': [],
      'shop-button': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-testing.webflow.css);
        ` }} />
        <span className="af-view">
          <div data-animation="default" data-collapse="medium" data-duration={300} data-easing="ease" data-easing2="linear" role="banner" className="af-class-navbar-quote w-nav">
            <div className="af-class-nav-bar-container w-container">
              {map(proxies['logo-button'], props => <a href="index.html" {...{...props, className: `af-class-brand w-nav-brand ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/NEW-pss-logo.svg" loading="lazy" width={92} alt="PSS Logo" className="af-class-image-3" /></React.Fragment>}</a>)}
              <nav role="navigation" className="af-class-nav-menu-2 w-nav-menu">
                {map(proxies['pm-button'], props => <a href="poster-maker.html" aria-current="page" {...{...props, className: `af-class-nav-bar-item w-inline-block w--current ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/Poster-Maker.png" loading="lazy" width={25} height alt="Poster Maker Page" className="af-class-nav-icon" />
                  <div className="af-class-text-block">Poster Maker</div>
                </React.Fragment>}</a>)}
                {map(proxies['folio-button'], props => <a href="folio.html" {...{...props, className: `af-class-nav-bar-item w-inline-block ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/Folio.png" loading="lazy" width={25} height={40} alt="Folio Page" className="af-class-nav-icon" />
                  <div className="af-class-text-block">Folio</div>
                </React.Fragment>}</a>)}
                {map(proxies['help-button'], props => <a href="#" {...{...props, className: `af-class-nav-bar-item w-inline-block ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/Help.png" loading="lazy" width={25} alt="Help Page" className="af-class-nav-icon" />
                  <div className="af-class-text-block">Help</div>
                </React.Fragment>}</a>)}
                {map(proxies['quote-button'], props => <a href="#" {...{...props, className: `af-class-nav-bar-item w-inline-block ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/Quote.png" loading="lazy" width={25} alt="Custom Quote Page" className="af-class-nav-icon" />
                  <div className="af-class-text-block">Quote</div>
                </React.Fragment>}</a>)}
                {map(proxies['shop-button'], props => <a href="#" {...{...props, className: `af-class-nav-bar-item w-inline-block ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/Shop.png" loading="lazy" width={25} alt="PSS Supplies Website" className="af-class-nav-icon" />
                  <div className="af-class-text-block">Supplies Shop</div>
                </React.Fragment>}</a>)}
              </nav>
              <div className="af-class-menu-button w-nav-button">
                <div className="af-class-icon-4 w-icon-nav-menu" />
              </div>
            </div>
          </div>
        </span>
      </span>
    )
  }
}

export default NavComponentView