import React from "react";
import ContactComponentView from "../views/ContactComponentView";

class ContactComponentController extends React.Component{
    state = {}
    render(){
        return(
        <ContactComponentView>
            <email-link onClick={()=>{
                window.location.href = "mailto:support@pssk12.com";
            }}/>
            <phone-link onClick={()=>{
                window.location.href = "tel:+18004859722"
            }}/>
            <contact-link onClick={()=>{
                window.location.pathname = "/contact-us"
            }}/>
        </ContactComponentView>)
    }

}
export default ContactComponentController