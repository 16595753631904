/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ContactComponentView from './ContactComponentView'
import NavComponentView from './NavComponentView'
import LogosContainerView from './LogosContainerView'
import HelpComponentView from './HelpComponentView'
import FooterComponentView from './FooterComponentView'
import MobileFooterView from './MobileFooterView'
import FooterContainerSmView from './FooterContainerSmView'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=644ad0ca9dae77a391ba11c2").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class IndexView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/IndexController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = IndexView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '644ad0ca9dae771847ba11c3'
    htmlEl.dataset['wfSite'] = '644ad0ca9dae77a391ba11c2'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {
      'hero-quote-button': [],
      'pm-learn-button': [],
      'pm-quote-button': [],
      'pm-instant-quote-link': [],
      'folio-try-button': [],
      'folio-learn-button': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-testing.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <ContactComponentView.Controller />
            <NavComponentView.Controller />
            <div>
              <div id="home" className="af-class-hero">
                <div className="w-container">
                  <div className="af-class-div-block-74">
                    <h2 data-w-id="579fc2da-2ae1-6751-1172-f5d37677c999" style={{opacity: 0}} className="af-class-h2 af-class-header af-class-slide">Low-cost poster making, simplified.</h2>
                    <p data-w-id="e30421c5-6d65-cb64-1884-120b1a7fee70" style={{opacity: 0}} className="af-class-h6 af-class-slide af-class-smaller">You print posters. We support you along the way!</p>
                    {map(proxies['hero-quote-button'], props => <div {...{...props, className: `af-class-white-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>
                      <div className="af-class-text-2">Get a Quote</div>
                    </React.Fragment>}</div>)}
                  </div>
                </div>
              </div>
              <div id="Announcement-Bar" className="af-class-banner-section af-class-test">
                <div className="af-class-div-block-79">
                  <div className="af-class-div-block-87">
                    <div className="af-class-h3 af-class-center af-class-white">Trusted by more than 2,500 schools!</div>
                  </div>
                </div>
                <section className="af-class-section-logo">
                  <div className="af-class-page-padding af-class-s0">
                    <div className="af-class-container-large">
                      <div className="af-class-padding-vertical af-class-padding-xxlarge">
                        <LogosContainerView.Controller />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="af-class-section">
              <div className="af-class-container-small w-container">
                <div className="af-class-columns-7 w-row">
                  <div className="af-class-column-74 w-col w-col-6">
                    <div data-w-id="560450c3-9f77-54ee-245b-78b9e4f0e4ac" style={{opacity: 0}} className="af-class-div-slide"><img src="images/LOD-ICON.svg" loading="lazy" width={67} alt className="af-class-image-14" />
                      <div className="af-class-text-block-80"><strong>Poster Maker</strong><br /><span className="af-class-text-span-3">System</span></div>
                    </div>
                    <div>
                      <p className="af-class-paragraph-4 af-class-slide">The PSS Poster Maker System is designed to make poster creation easy and effortless for K-12 educators. PSS provides the hardware, software and service needed to create engaging posters for years to come.</p>
                      <div className="af-class-button-div">
                        {map(proxies['pm-learn-button'], props => <div {...{...props, className: `af-class-blue-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>
                          <div className="af-class-text">Learn More</div>
                        </React.Fragment>}</div>)}
                        {map(proxies['pm-quote-button'], props => <div {...{...props, className: `af-class-white-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>
                          <div className="af-class-text-2">Get a Quote</div>
                        </React.Fragment>}</div>)}
                      </div>
                    </div>
                  </div>
                  <div className="af-class-column-73 w-col w-col-6"><img src="images/PosterMakerpsslogo.png" loading="lazy" width={322} sizes="(max-width: 479px) 85vw, 322px" alt srcSet="images/PosterMakerpsslogo-p-500.png 500w, images/PosterMakerpsslogo.png 740w" /></div>
                </div>
              </div>
              <div className="af-class-container-4 w-container">
                <div className="af-class-h9">Includes:</div>
                <div className="w-layout-grid af-class-grid">
                  <div id="w-node-_560450c3-9f77-54ee-245b-78b9e4f0e4c4-47ba11c3" data-w-id="560450c3-9f77-54ee-245b-78b9e4f0e4c4" style={{opacity: 0}} className="af-class-div-block-4"><img src="images/Frame-4.svg" loading="lazy" width={67} alt className="af-class-icon-home-page" />
                    <div id="w-node-_560450c3-9f77-54ee-245b-78b9e4f0e4c6-47ba11c3" className="af-class-p5 af-class-icon-label">24” Poster Maker Printer</div>
                  </div>
                  <div id="w-node-_560450c3-9f77-54ee-245b-78b9e4f0e4c8-47ba11c3" data-w-id="560450c3-9f77-54ee-245b-78b9e4f0e4c8" style={{opacity: 0}} className="af-class-div-block-4"><img src="images/Frame-5.svg" loading="lazy" width={67} alt className="af-class-icon-home-page" />
                    <div id="w-node-_560450c3-9f77-54ee-245b-78b9e4f0e4ca-47ba11c3" className="af-class-p5 af-class-icon-label">PC Workstation</div>
                  </div>
                  <div id="w-node-_560450c3-9f77-54ee-245b-78b9e4f0e4cc-47ba11c3" data-w-id="560450c3-9f77-54ee-245b-78b9e4f0e4cc" style={{opacity: 0}} className="af-class-div-block-4"><img src="images/Frame-7.svg" loading="lazy" width={67} alt className="af-class-icon-home-page" />
                    <div id="w-node-_560450c3-9f77-54ee-245b-78b9e4f0e4ce-47ba11c3" className="af-class-p5 af-class-icon-label">Pre-installed Software</div>
                  </div>
                  <div id="w-node-_560450c3-9f77-54ee-245b-78b9e4f0e4d0-47ba11c3" data-w-id="560450c3-9f77-54ee-245b-78b9e4f0e4d0" style={{opacity: 0}} className="af-class-div-block-4"><img src="images/Frame-6.svg" loading="lazy" width={67} alt className="af-class-icon-home-page" />
                    <div id="w-node-_560450c3-9f77-54ee-245b-78b9e4f0e4d2-47ba11c3" className="af-class-p5 af-class-icon-label">High-resolution Scanner</div>
                  </div>
                  <div id="w-node-_560450c3-9f77-54ee-245b-78b9e4f0e4d4-47ba11c3" data-w-id="560450c3-9f77-54ee-245b-78b9e4f0e4d4" style={{opacity: 0}} className="af-class-div-block-4"><img src="images/Frame-8.svg" loading="lazy" width={67} alt className="af-class-icon-home-page" />
                    <div id="w-node-_560450c3-9f77-54ee-245b-78b9e4f0e4d6-47ba11c3" className="af-class-p5 af-class-icon-label">Set of Ink</div>
                  </div>
                  <div id="w-node-_560450c3-9f77-54ee-245b-78b9e4f0e4d8-47ba11c3" data-w-id="560450c3-9f77-54ee-245b-78b9e4f0e4d8" style={{opacity: 0}} className="af-class-div-block-4"><img src="images/Frame-3.svg" loading="lazy" width={67} alt className="af-class-icon-home-page" />
                    <div id="w-node-_560450c3-9f77-54ee-245b-78b9e4f0e4da-47ba11c3" className="af-class-p5 af-class-icon-label">One Year Warranty</div>
                  </div>
                </div>
              </div>
              <div className="af-class-container-12 w-container"><img src="images/title-3.png" loading="lazy" width={95} alt className="af-class-image-7" />
                <div className="af-class-h10">Title 1 and Title 3 eligible! Get an {map(proxies['pm-instant-quote-link'], props => <a href="#" {...{...props, className: `af-class-link-3 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>instant quote</React.Fragment>}</a>)} today!</div>
              </div>
            </div>
            <div className="af-class-section-3"><img src="images/NEW-Folio-logo.png" loading="lazy" alt className="af-class-image" />
              <div className="af-class-container-small w-container">
                <h1 data-w-id="cfca61cc-9d17-589a-a4ed-a66902625755" style={{opacity: 0}} className="af-class-h4 af-class-book af-class-white">High-quality, standards-aligned,<br />ready-to-print resources</h1>
                <p data-w-id="cfca61cc-9d17-589a-a4ed-a66902625759" style={{opacity: 0}} className="af-class-p3 af-class-white">What's a poster maker without great posters to print? Folio is our library of hundreds of posters, games, and interactive resources designed by a team of educators and designers. Every resource is available in English and Spanish.</p>
              </div>
              <div className="af-class-button-div af-class-center">
                {map(proxies['folio-try-button'], props => <a href="#" {...{...props, className: `af-class-white-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Try out Folio</React.Fragment>}</a>)}
                {map(proxies['folio-learn-button'], props => <a href="#" {...{...props, className: `af-class-blue-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Learn More</React.Fragment>}</a>)}
              </div>
            </div>
            <div id="about-us-section" className="af-class-section-19">
              <div className="af-class-container-5 w-container">
                <div className="af-class-columns w-row">
                  <div className="af-class-column-75 w-col w-col-6"><img src="images/photos.png" width={547} alt style={{opacity: 0}} sizes="(max-width: 479px) 100vw, (max-width: 767px) 89vw, (max-width: 991px) 44vw, (max-width: 1279px) 45vw, 460px" data-w-id="3271f768-f590-99db-f96d-6ce94a490fd8" loading="lazy" srcSet="images/photos-p-500.png 500w, images/photos-p-800.png 800w, images/photos.png 922w" /></div>
                  <div className="af-class-column-95 w-col w-col-6">
                    <h1 data-w-id="3271f768-f590-99db-f96d-6ce94a490fda" style={{opacity: 0}} className="af-class-h5">Who are we?</h1>
                    <p className="af-class-paragraph-4 af-class-slide">At PSS, we believe in educators and we’re here to support you! Our small team is comprised of K-12 teachers, creatives, and highly trained support staff. Most importantly, we are bound by our mission to help educators provide meaningful and effective learning experiences for our kids.<br /><br />PSS is a minority &amp; veteran owned company.</p>
                  </div>
                </div>
              </div>
              <div className="af-class-container-8 w-container">
                <h1 data-w-id="3271f768-f590-99db-f96d-6ce94a490fdf" style={{opacity: 0}} className="af-class-h5">What do customers say about PSS?</h1>
                <div className="af-class-columns-14 w-row">
                  <div className="af-class-column-80 w-col w-col-6">
                    <p className="af-class-paragraph-4 af-class-slide">“The customer service at PSS is truly amazing. From quickly putting together a quote during quarantine (and then adjusting it at the last minute to suit my school’s changing needs), to delivering during a pandemic, to tailoring a virtual training session just for me – they go the extra mile – and then some – for customers. I am so appreciative!”</p>
                    <div className="af-class-div-block-49"><img src="images/ann_1ann.png" loading="lazy" alt className="af-class-image-5" />
                      <div className="af-class-p5 af-class-inline"><strong className="af-class-bold-text-5">Ann S.</strong><br />School Librarian, Clover High School</div>
                    </div>
                  </div>
                  <div className="af-class-column-107 w-col w-col-6">
                    <p className="af-class-paragraph-4 af-class-slide">“Searching for a poster maker and cool laminator that met the needs of our school was easier when working with PSS. They were wonderful at communicating with us on what would be best for us. We are excited to use our new laminator and poster maker!”</p>
                    <div className="af-class-div-block-48"><img src="images/lauren_1lauren.png" loading="lazy" alt className="af-class-image-5" />
                      <div className="af-class-p5 af-class-inline"><strong>Lauren G.<br /></strong>Technology Coordinator, Corley Elementary</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <HelpComponentView.Controller />
            <FooterComponentView.Controller />
            <MobileFooterView.Controller />
            <FooterContainerSmView.Controller />
          </div>
        </span>
      </span>
    )
  }
}

export default IndexView

/* eslint-enable */