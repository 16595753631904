import React from "react";
import ContactUsView from "../views/ContactUsView";
import HubspotForm from 'react-hubspot-form';


class ContactUsController extends React.Component{
    state = {}
    render(){
        return(
        <ContactUsView>
            <contact-phone/>
            <contact-email/>
            <contact-form-container  style={{paddingTop: '44px'}}>
                <HubspotForm
                        portalId='7314258'
                        formId='5ea32e99-96cd-4bb4-9f8d-7f8af4571133'
                        loading={<div>Loading...</div>}
                    >
                    </HubspotForm>
            </contact-form-container>
        </ContactUsView>)
    }

}
export default ContactUsController