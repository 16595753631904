import React, {useState, useEffect} from "react";
import SuppliesQuoteView from "../views/SuppliesQuoteView";
import HubspotForm from 'react-hubspot-form'
import { useLoading } from "../tools/LoadingContext";

const formMessage = [
    <h2>Fill out this form to confirm your quote!</h2>, 
    <>
        <h2>Thanks for submitting your quote!</h2>
        <h3 style={{fontWeight: "normal", width: "100%"}}>Our team has been notified of your pending quote and will reach out to you shortly!<br/>
        Please look out for an email with your quote information!<br/>
        Thanks,<br/>
        PSS Team
        </h3>
        <button className="hs-button" onClick={()=>{
            window.open("https://shop.pssk12.com")
        }}>Return to Shop</button>
    </>,
    <>
        <h2>Something went wrong with your quote!</h2>
        <h3>Please try again or contact us directly at <a href="mailto:orders@pssk12.com">orders@pssk12.com</a>.</h3>
    </>
]


const SuppliesQuoteController =()=> {

    const [lineItems, setLineItems] = useState([]);
    const [orderData, setOrderData] = useState(null);
    const [formStatus, setFormStatus] = useState(0)
    const { showLoading, hideLoading } = useLoading();

    useEffect(()=>{
        hideLoading()
    },[formStatus])

     useEffect(()=>{
        window.addEventListener('message', event => {
            if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
                const params = new URLSearchParams(document.location.search);
                const items = params.get("items").split("\n");
                let tempLineItems = []
                var userMessage = ""
                for(var i = 0; i < items.length - 1; i++){
                    const lineItem = items[i].split("_")
                    console.log(lineItem)
                    userMessage += "&#8226;" + lineItem[0] + "(" + lineItem[4] + ") x " + lineItem[2] + "\n";
                    tempLineItems.push({
                        variant_id: lineItem[1],
                        quantity: lineItem[2],
                        discount: (/true/i).test(lineItem[3])
                     })
                }
                setLineItems(tempLineItems)

                let tc =document.querySelector('[id^="TICKET.content"]');
                let ticketContent = document.getElementById(tc.id);
                ticketContent.innerHTML = userMessage;
                ticketContent.focus()

                let tn = document.querySelector('[id^="TICKET.subject"]')
                let ticketName = document.getElementById(tn.id);
                ticketName.focus()
            }

        })
    });

    useEffect(()=>{
        const handlePostRequest = async () => {
        try {
            showLoading()
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/draft/create_draft', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "data": orderData
                }),
            });

            if (!response.ok) {
                setFormStatus(2)
                throw new Error('Network response was not ok');
            }
            // Handle the response data as needed
            setFormStatus(1)

            } catch (error) {
                // Handle errors during the fetch
                console.error('Error during POST request:', error);
                setFormStatus(2)
            }
        };
        if(orderData){
            handlePostRequest()
        }
    }, [orderData])
    
    return(
        <SuppliesQuoteView>
            <logo-button href="/"/>
            <quote-message style={{textAlign:"center", width: "100%"}}>
                {formMessage[formStatus]}
            </quote-message>
            <quote-form-container>
                    <HubspotForm
                        portalId='7314258'
                        formId= {process.env.REACT_APP_SUPPLIES_FORM}
                        loading={<div>Loading...</div>}
                        onSubmit={(e)=>{
                            var formData = e.reduce((acc, obj)=>{
                                acc[obj.name] = obj.value
                                return(acc)
                            })
                        
                            var formattedOrderData = {
                                "first_name": formData.firstname,
                                "last_name": formData.lastname,
                                "email": formData.value,
                                "address": {
                                    "company": formData.company,
                                    "country": "United States",
                                    "zip": formData.zip,
                                    "default": true
                                },
                                "line_items": lineItems
                            }

                            console.log(formData["TICKET.po__"])

                            if(formData["TICKET.po__"] !== ""){
                                formattedOrderData.po_number = formData["TICKET.po__"]
                            }
                            console.log(formattedOrderData)

                            setOrderData(formattedOrderData)
                        
                        }}
                    >
                    </HubspotForm>
            </quote-form-container>
        </SuppliesQuoteView>
    );
}


export default SuppliesQuoteController