/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ContactComponentView from './ContactComponentView'
import NavComponentView from './NavComponentView'
import MobileFooterView from './MobileFooterView'
import FooterComponentView from './FooterComponentView'
import FooterContainerSmView from './FooterContainerSmView'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=644ad0ca9dae77a391ba11c2").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class HelpView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/HelpController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = HelpView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '64651829da5cf2413ba1d906'
    htmlEl.dataset['wfSite'] = '644ad0ca9dae77a391ba11c2'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = HelpView.Controller !== HelpView ? transformProxies(this.props.children) : {
      'help-yt': [],
      'help-pm': [],
      'help-mc': [],
      'help-cl': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-testing.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <ContactComponentView.Controller />
            <NavComponentView.Controller />
            <section className="af-class-section-24 af-class-section-25">
              <div className="af-class-container-33 w-container">
                <div className="af-class-text-block-100">Welcome to our help page!</div>
                <div className="af-class-paragraph-4">Here you can access comprehensive manuals and guides to assist you in using and troubleshooting our device. </div>
                <div className="af-class-columns-13 w-row">
                  <div className="af-class-column-105 w-col w-col-6">
                    {map(proxies['help-yt'], props => <div {...{...props, className: `af-class-help-block af-class-youtube ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>
                      <div className="af-class-div-block-80"><img src="images/youtube-video.jpg" loading="lazy" width={380} sizes="(max-width: 479px) 54vw, (max-width: 767px) 37vw, (max-width: 991px) 272px, 349.4765625px" alt srcSet="images/youtube-video-p-500.jpg 500w, images/youtube-video-p-800.jpg 800w, images/youtube-video.jpg 1280w" className="af-class-image-30" />
                        <div className="af-class-div-block-86"><img src="images/youtube_318-566773.jpg.png" loading="lazy" width={43} alt className="af-class-image-31" />
                          <div className="af-class-text-block-105">Watch our video tutorials! </div>
                        </div>
                      </div>
                    </React.Fragment>}</div>)}
                  </div>
                  <div className="af-class-column-106 w-col w-col-6">
                    <div className="af-class-div-block-83">
                      {map(proxies['help-pm'], props => <div {...{...props, className: `af-class-help-block af-class-short ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>
                        <div className="af-class-div-block-84"><img src="images/pm.png" loading="lazy" width={80} sizes="(max-width: 1279px) 80px, (max-width: 1439px) 6vw, 80px" alt srcSet="images/pm-p-500.png 500w, images/pm.png 800w" className="af-class-image-27" />
                          <div className="af-class-p4">Poster Maker manual</div>
                          <div className="af-class-text-block-103">→</div>
                        </div>
                      </React.Fragment>}</div>)}
                      {map(proxies['help-mc'], props => <div {...{...props, className: `af-class-help-block af-class-short ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>
                        <div className="af-class-div-block-84"><img src="images/mc.png" loading="lazy" width={80} sizes="(max-width: 1279px) 80px, (max-width: 1439px) 6vw, 80px" alt srcSet="images/mc-p-500.png 500w, images/mc.png 800w" className="af-class-image-28" />
                          <div className="af-class-p4">Magic Cutter manual</div>
                          <div className="af-class-text-block-103">→</div>
                        </div>
                      </React.Fragment>}</div>)}
                      {map(proxies['help-cl'], props => <div {...{...props, className: `af-class-help-block af-class-short ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>
                        <div className="af-class-div-block-84"><img src="images/cl.png" loading="lazy" width={80} sizes="(max-width: 1279px) 80px, (max-width: 1439px) 6vw, 80px" alt srcSet="images/cl-p-500.png 500w, images/cl.png 800w" className="af-class-image-29" />
                          <div className="af-class-p4">Cool Lam manual</div>
                          <div className="af-class-text-block-103">→</div>
                        </div>
                      </React.Fragment>}</div>)}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <MobileFooterView.Controller />
            <FooterComponentView.Controller />
            <FooterContainerSmView.Controller />
          </div>
        </span>
      </span>
    )
  }
}

export default HelpView

/* eslint-enable */