/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ContactComponentView from './ContactComponentView'
import NavComponentView from './NavComponentView'
import HelpComponentView from './HelpComponentView'
import MobileFooterView from './MobileFooterView'
import FooterComponentView from './FooterComponentView'
import FooterContainerSmView from './FooterContainerSmView'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=644ad0ca9dae77a391ba11c2").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class PosterMakerView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/PosterMakerController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = PosterMakerView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '644ffb08735fb447758eabe5'
    htmlEl.dataset['wfSite'] = '644ad0ca9dae77a391ba11c2'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = PosterMakerView.Controller !== PosterMakerView ? transformProxies(this.props.children) : {
      'hero-quote-button': [],
      'package-quote-button': [],
      'package-contact-link': [],
      'pricing-quote-button': [],
      'pricing-contact-link': [],
      'folio-button': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-testing.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body-2">
            <ContactComponentView.Controller />
            <NavComponentView.Controller />
            <div className="af-class-section-6">
              <div className="af-class-small-container w-container">
                <div className="af-class-columns-2 w-row">
                  <div className="af-class-column-81 w-col w-col-6">
                    <div className="af-class-upper-case-label af-class-white af-class-header">POSTER MAKER</div>
                    <div className="af-class-pm-header">
                      <div data-w-id="312627f5-25b0-5674-679e-9bd049832460" style={{opacity: 0}} className="af-class-h2 af-class-header af-class-white af-class-slide"><strong data-w-id="ee1c1da0-2036-1be4-e1b9-f2a29efebc4e" style={{opacity: 0}}>Make posters for less than $4 each</strong></div>
                    </div>
                    <p data-w-id="312627f5-25b0-5674-679e-9bd049832462" style={{opacity: 0}} className="af-class-paragraph-4 af-class-header af-class-white">Engage your students while protecting the school’s budget. With PSS Poster Makers, your school will create engaging posters in-house for less than $4. A similar print would cost $20+ at your local print shop. </p>
                    <div className="af-class-div-block-61">
                      {map(proxies['hero-quote-button'], props => <a href="#" {...{...props, className: `af-class-white-button af-class-header w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Get a Quote</React.Fragment>}</a>)}
                    </div>
                  </div>
                  <div className="af-class-column-76 w-col w-col-6" />
                </div>
              </div>
            </div>
            <div className="af-class-section-20">
              <div className="af-class-container-small w-container">
                <div className="af-class-main-column w-row">
                  <div className="af-class-column-86 w-col w-col-6"><img src="images/school-photo.png" loading="lazy" width={608} sizes="(max-width: 479px) 100vw, (max-width: 767px) 71vw, (max-width: 991px) 354px, 460px" alt srcSet="images/school-photo-p-500.png 500w, images/school-photo-p-800.png 800w, images/school-photo.png 853w" className="af-class-image-18" /></div>
                  <div className="af-class-column-91 w-col w-col-6">
                    <div>
                      <div className="af-class-upper-case-label">How it works</div>
                      <h1 className="af-class-h5 af-class-bold">A stress-free experience! </h1>
                    </div>
                    <div className="af-class-how-it-works"><img src="images/Group-2225.png" loading="lazy" width={69} alt className="af-class-icon" />
                      <p className="af-class-paragraph-4 af-class-slide"><span className="af-class-text-span-5"><strong>Installation<br /></strong></span>After receiving your purchase order, we’ll schedule an installation. Installations are completed in-person by our team or internally by your team with our guidance. The typical installation takes 40 minutes.</p>
                    </div>
                    <div className="af-class-how-it-works"><img src="images/Group-2226.png" loading="lazy" width={69} alt className="af-class-icon" />
                      <p className="af-class-paragraph-4 af-class-slide"><span className="af-class-text-span-5"><strong>Training</strong></span><br />Following installation, we’ll conduct training. Training can occur in-person, via live webinar or self paced using our training videos! Your team will be confidently printing in less than 20 minutes.</p>
                    </div>
                    <div className="af-class-how-it-works"><img src="images/Group-2227.png" loading="lazy" width={69} alt className="af-class-icon" />
                      <p className="af-class-paragraph-4 af-class-slide"><span className="af-class-text-span-5"><strong>Maintenance </strong></span><br />You print posters. We service printers. You should not have to become a printer repair expert. Each PSS Poster Maker is covered by our one-year worry free, PSS Cares Warranty. Schools may add additional years</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-padding-vertical af-class-padding-xxlarge" />
            </div>
            <div className="af-class-section-20">
              <div className="af-class-container-small w-container">
                <div className="af-class-main-column af-class-inverse w-row">
                  <div className="af-class-column-79 w-col w-col-6">
                    <div className="af-class-div-block-66">
                      <div className="af-class-upper-case-label">What you get</div>
                      <h1 className="af-class-h5 af-class-bold"><strong className="af-class-h5 af-class-bold">The PSS Poster Maker System</strong></h1>
                      <p className="af-class-paragraph-4 af-class-slide">Instead of asking your IT team to build a poster maker system, partner with the top K-12 poster maker provider in the US. Over the last 27+ years, PSS has assembled the best components to ensure making posters is easy and effortless. The system includes:<br />‍<br />• 24” Poster Maker Printer<br />• PC Workstation<br />• Pre-installed Software<br />• High-resolution Scanner<br />• Set of Ink<br />•<strong> </strong>One Year Warranty</p>
                      {map(proxies['package-quote-button'], props => <a href="#" {...{...props, className: `af-class-blue-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Get a Quote</React.Fragment>}</a>)}
                      <div className="af-class-caption">Using Title 1 or title 3 funds? {map(proxies['package-contact-link'], props => <a href="#" {...{...props, className: `af-class-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Contact Us </React.Fragment>}</a>)}to learn more!</div>
                    </div>
                  </div>
                  <div data-w-id="0a7bc6c3-5331-6e8d-718c-5eb8290b89d6" style={{opacity: 0}} className="af-class-column-92 w-col w-col-6"><img src="images/system.png" loading="lazy" srcSet="images/system-p-500.png 500w, images/system-p-800.png 800w, images/system.png 875w" sizes="(max-width: 479px) 97vw, (max-width: 767px) 75vw, (max-width: 991px) 389.390625px, 505.9921875px" alt className="af-class-image-15" /></div>
                </div>
              </div>
              <div className="af-class-padding-vertical af-class-padding-xxlarge" />
            </div>
            <div className="af-class-section-20">
              <div className="af-class-container-small w-container">
                <div className="af-class-main-column w-row">
                  <div data-w-id="9ec0dd71-006f-aa84-ea57-4a983a08cc9e" style={{opacity: 0}} className="af-class-column-96 w-col w-col-6"><img src="images/paper-options.png" loading="lazy" sizes="(max-width: 767px) 89vw, (max-width: 991px) 354px, 460px" srcSet="images/paper-options-p-500.png 500w, images/paper-options-p-800.png 800w, images/paper-options.png 917w" alt /></div>
                  <div className="af-class-column-93 w-col w-col-6">
                    <div>
                      <div>
                        <div className="af-class-upper-case-label">Multi-media</div>
                        <h1 className="af-class-h5 af-class-bold">Vinyl, Peel &amp; Stick, Magnet, Canvas…the ideas are endless!</h1>
                      </div>
                    </div>
                    <p className="af-class-paragraph-4 af-class-slide">Save hundreds of dollars by creating your own vinyl banners in house. Or better yet, raise funds by selling school branded magnets. The possibilities are endless with the PSS Poster Maker System.</p>
                  </div>
                </div>
              </div>
              <div className="af-class-padding-vertical af-class-padding-xxlarge" />
            </div>
            <div className="af-class-section-20 af-class-dark">
              <div className="af-class-container-small w-container">
                <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf856f-758eabe5" className="af-class-div-block-54">
                  <div className="af-class-upper-case-label">PSS Care Warranty</div>
                  <h2 data-w-id="97d63a67-ff69-39a4-c9b4-407414bf8570" style={{opacity: 0}} className="af-class-h5"><strong className="af-class-h5 af-class-bold">What is the PSS Care Warranty? 🔧</strong></h2>
                  <div className="af-class-spacer-xs" />
                  <p className="af-class-paragraph-4 af-class-slide">PSS Care protects your initial investment. With a PSS Care Plan (extended warranty and training), we ensure that your Poster Maker does not sit unused, collecting dust. PSS has provided schools with Poster Makers for over 27 years. With regular maintenance and training, our poster makers are operational for over 5 years. Some systems have operated for more than 10 years. </p>
                </div>
                <div className="af-class-price-category-wrap">
                  <div className="w-layout-grid af-class-outer-chart-grid">
                    <div className="w-layout-grid af-class-pricing-chart-grid">
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf8578-758eabe5" className="af-class-table-cell-title" />
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf8579-758eabe5" className="af-class-table-cell af-class-white">
                        <div className="af-class-text-block-84">DIY / Copier Dealers</div>
                      </div>
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf857c-758eabe5" className="af-class-table-cell">
                        <div><img src="https://uploads-ssl.webflow.com/63dabbb2d238ed7213920cfb/63fd2f69f3aa2e49ac2bb530_NEW%20pss%20logo.svg" loading="lazy" alt /></div>
                      </div>
                    </div>
                    <div className="w-layout-grid af-class-pricing-chart-grid">
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf8580-758eabe5" className="af-class-table-cell-title">
                        <div className="af-class-paragraph-4">Phone support (based in US)<br />*80% of issues resolved via phone</div>
                      </div>
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf8588-758eabe5" className="af-class-table-cell af-class-white"><img src="images/x.svg" loading="lazy" width={25} alt className="af-class-x" /></div>
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf858a-758eabe5" className="af-class-table-cell"><img src="images/checkmark.svg" loading="lazy" width={25} alt className="af-class-x" /></div>
                    </div>
                    <div className="w-layout-grid af-class-pricing-chart-grid">
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf858d-758eabe5" className="af-class-table-cell-title">
                        <div className="af-class-paragraph-4">Covers parts and site visit</div>
                      </div>
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf8590-758eabe5" className="af-class-table-cell af-class-white"><img src="images/x.svg" loading="lazy" width={25} alt className="af-class-x" /></div>
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf8592-758eabe5" className="af-class-table-cell"><img src="images/checkmark.svg" loading="lazy" width={25} alt className="af-class-x" /></div>
                    </div>
                    <div className="w-layout-grid af-class-pricing-chart-grid">
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf8595-758eabe5" className="af-class-table-cell-title">
                        <div className="af-class-paragraph-4">Replacement printer, if unable to fix</div>
                      </div>
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf8598-758eabe5" className="af-class-table-cell af-class-white"><img src="images/x.svg" loading="lazy" width={25} alt className="af-class-x" /></div>
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf859a-758eabe5" className="af-class-table-cell"><img src="images/checkmark.svg" loading="lazy" width={25} alt className="af-class-bold-text-2" /></div>
                    </div>
                    <div className="w-layout-grid af-class-pricing-chart-grid">
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf859d-758eabe5" className="af-class-table-cell-title">
                        <div className="af-class-paragraph-4">Poster Maker training via monthly webinars</div>
                      </div>
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf85a0-758eabe5" className="af-class-table-cell af-class-white"><img src="images/x.svg" loading="lazy" width={25} alt className="af-class-x" /></div>
                      <div id="w-node-_97d63a67-ff69-39a4-c9b4-407414bf85a2-758eabe5" className="af-class-table-cell"><img src="images/checkmark.svg" loading="lazy" width={25} alt className="af-class-x" /></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-padding-vertical af-class-padding-xxlarge" />
            </div>
            <div className="af-class-section-15">
              <div className="af-class-container-small af-class-small w-container">
                <div className="af-class-upper-case-label af-class-center af-class-white">Pricing</div>
                <div className="af-class-div-block-60">
                  <h1 className="af-class-h5 af-class-bold af-class-white"><strong>Customize your quote today. Starting at $5,129!</strong></h1>
                  {map(proxies['pricing-quote-button'], props => <a href="#" {...{...props, className: `af-class-white-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Customize Quote</React.Fragment>}</a>)}
                  <div className="af-class-caption af-class-white">Using Title 1 or title 3 funds? {map(proxies['pricing-contact-link'], props => <a href="#" {...{...props, className: `af-class-link-2 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Contact Us </React.Fragment>}</a>)}to learn more!</div>
                </div>
              </div>
            </div>
            <div className="af-class-bsb-selection-1">
              <h1 className="af-class-h5 af-class-center af-class-slide">Trusted by over 2,500 schools!⭐</h1>
              <div data-w-id="7e30f1ff-54f8-50bd-6578-80c2028c2f64" style={{opacity: 0}} className="af-class-bsb-grid-1">
                <div className="af-class-bsb-wrap-wrap">
                  <a href="#" className="af-class-bsb-pic-1 w-inline-block"><img src="images/ann_1ann.png" loading="lazy" alt className="af-class-bsb-pic-1-1" /></a>
                  <div className="af-class-bsb-wrap-text-1">
                    <div className="af-class-bsb-text-1-1"><strong>- Ann Shearer</strong><br /></div>
                    <div className="af-class-bsb-text-1-2">School Librarian, Clover High Schooll</div>
                  </div>
                </div>
                <div className="af-class-bsb-wrap-text">
                  <div className="af-class-paragraph-4">"“The customer service at PSS is truly amazing. From quickly putting together a quote &nbsp;to delivering during a pandemic, to tailoring a virtual training session just for me – they go the extra mile – and then some – for customers. I am so appreciative!”</div>
                </div>
              </div>
              <div className="af-class-div-block-89">
                <div className="af-class-bsb-wrap-2">
                  <div className="af-class-div-block-57">
                    <div id="w-node-_7e30f1ff-54f8-50bd-6578-80c2028c2f72-758eabe5" className="af-class-bsb-grid-1 af-class-testimonial">
                      <div className="af-class-bsb-wrap-wrap">
                        <a href="#" className="af-class-bsb-pic-1 w-inline-block"><img src="images/testimonial_angie.png" loading="lazy" alt className="af-class-bsb-pic-1-1" /></a>
                        <div className="af-class-bsb-wrap-text-1">
                          <div className="af-class-bsb-text-1-1"><strong>- Angie Taylor</strong></div>
                          <div className="af-class-bsb-text-1-2">Secretary, Northwest Cabarrus High School</div>
                        </div>
                      </div>
                      <div className="af-class-bsb-wrap-text">
                        <div className="af-class-paragraph-4">"I have used the Poster Maker for hallways, events, and sports promotions for students and parents. &nbsp;I have really enjoyed working with the Learn OnDemand System and the awesome staff at PSS!"</div>
                      </div>
                    </div>
                  </div>
                  <div id="w-node-_7e30f1ff-54f8-50bd-6578-80c2028c2f7f-758eabe5">
                    <div id="w-node-_7e30f1ff-54f8-50bd-6578-80c2028c2f80-758eabe5" className="af-class-bsb-grid-1 af-class-_3rd">
                      <div className="af-class-bsb-wrap-wrap">
                        <a href="#" className="af-class-bsb-pic-1 w-inline-block"><img src="images/Mask-group-1.png" loading="lazy" alt className="af-class-bsb-pic-1-1" /></a>
                        <div className="af-class-bsb-wrap-text-1">
                          <div className="af-class-bsb-text-1-1">Robin Williams<br /></div>
                          <div className="af-class-bsb-text-1-2">Teacher Librarian, East Mecklenburg HS</div>
                        </div>
                      </div>
                      <div className="af-class-bsb-wrap-text">
                        <div className="af-class-paragraph-4">"A poster printer is invaluable in this day of visual graphics. We know as educators things have to be eye catching and informative to catch the interest of our students - that's society today, the infographic is king."</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-section-14 af-class-position-relative af-class-overflow-hidden">
              <div className="af-class-small-container w-container">
                <div className="w-layout-grid af-class-brix---grid-top---text-2-buttons">
                  <div data-w-id="a10e91e3-c974-7106-d807-44b227802268" style={{opacity: 0}} className="af-class-brix---inner-container-490px">
                    <div className="af-class-brix---mg-bottom-12px">
                      <div className="af-class-brix---color-neutral-800">
                        <h2 className="af-class-h5">#madewithpss</h2>
                      </div>
                    </div>
                    <div className="af-class-brix---color-neutral-600">
                      <p className="af-class-paragraph-4 af-class-slide">Our community of teachers is growing every day. We love seeing what you all create!</p>
                    </div>
                  </div>
                  <div id="w-node-a10e91e3-c974-7106-d807-44b227802270-758eabe5" className="af-class-brix---buttons-row-right---t-left">
                    {map(proxies['folio-button'], props => <a href="#" {...{...props, className: `af-class-blue-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Follow us on Instagram</React.Fragment>}</a>)}
                  </div>
                </div>
              </div>
              <div data-w-id="8510fe17-94cd-7d92-ece2-f644578138de" style={{opacity: 0}} className="w-layout-grid af-class-grid-3-columns af-class-gallery-v5">
                <div className="w-layout-grid af-class-grid-1-column af-class-gap-row-28px af-class-gap-row-16-mbp">
                  <div className="af-class-image-wrapper af-class-overflow-hidden af-class-border-radius-24px"><img src="images/1-2.png" alt sizes="(max-width: 479px) 45vw, (max-width: 767px) 27vw, (max-width: 991px) 29vw, (max-width: 1439px) 30vw, 31vw" srcSet="images/1-2-p-500.png 500w, images/1-2-p-800.png 800w, images/1-2.png 998w" className="af-class-image-32" /></div>
                  <div className="af-class-image-wrapper af-class-overflow-hidden af-class-border-radius-24px"><img src="images/5.png" srcSet="images/5-p-500.png 500w, images/5-p-800.png 800w, images/5.png 997w" width={481} sizes="(max-width: 479px) 45vw, (max-width: 767px) 27vw, (max-width: 991px) 29vw, (max-width: 1919px) 30vw, 481px" alt className="af-class-image-32" /></div>
                </div>
                <div className="w-layout-grid af-class-grid-1-column af-class-gap-row-28px af-class-gap-row-16-mbp">
                  <div className="af-class-image-wrapper af-class-overflow-hidden af-class-border-radius-24px"><img src="images/6.png" alt sizes="(max-width: 479px) 45vw, (max-width: 767px) 27vw, (max-width: 991px) 29vw, (max-width: 1439px) 30vw, 31vw" srcSet="images/6-p-500.png 500w, images/6-p-800.png 800w, images/6.png 994w" className="af-class-image-32" /></div>
                  <div className="af-class-image-wrapper af-class-overflow-hidden af-class-border-radius-24px"><img src="images/4.png" alt sizes="(max-width: 479px) 45vw, (max-width: 767px) 27vw, (max-width: 991px) 29vw, (max-width: 1439px) 30vw, 31vw" srcSet="images/4-p-500.png 500w, images/4-p-800.png 800w, images/4.png 996w" className="af-class-image-32" /></div>
                </div>
                <div className="w-layout-grid af-class-grid-1-column af-class-gap-row-28px af-class-hidden-mbp">
                  <div className="af-class-image-wrapper af-class-overflow-hidden af-class-border-radius-24px"><img src="images/2.png" alt sizes="(max-width: 479px) 100vw, (max-width: 767px) 27vw, (max-width: 991px) 29vw, (max-width: 1439px) 30vw, 31vw" srcSet="images/2-p-500.png 500w, images/2-p-800.png 800w, images/2.png 996w" className="af-class-image-32" /></div>
                  <div className="af-class-image-wrapper af-class-overflow-hidden af-class-border-radius-24px"><img src="images/3-2.png" srcSet="images/3-2-p-500.png 500w, images/3-2-p-800.png 800w, images/3-2.png 1032w" width={422} sizes="(max-width: 479px) 100vw, (max-width: 767px) 27vw, (max-width: 991px) 29vw, (max-width: 1439px) 30vw, 422px" alt className="af-class-image-32" /></div>
                </div>
              </div>
            </div>
            <HelpComponentView.Controller />
            <MobileFooterView.Controller />
            <FooterComponentView.Controller />
            <FooterContainerSmView.Controller />
          </div>
        </span>
      </span>
    )
  }
}

export default PosterMakerView

/* eslint-enable */