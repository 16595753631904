import React from "react";
import FooterContainerSmView from "../views/FooterContainerSmView";

class FooterContainerSmController extends React.Component{
    state = {}
    render(){
        return(
        <FooterContainerSmView>
            <pp-link onClick={()=>{
                window.open("https://cc-cdn.pss.co/public/PSS%20Privacy%20Policy.pdf","_blank")
            }}/>
            <tos-link onClick={()=>{
                window.open("https://cc-cdn.pss.co/public/PSS%20TOS.pdf","_blank")
            }}/>
        
            <support-link id="support-link"onClick={()=>{
                window.location.href = "mailto:support@pssk12.com";
            }}>support@pssk12.com</support-link>
    
            <ig-link onClick={()=>{window.open("https://www.instagram.com/pssbulletin/", "_blank")}}/>
            <tt-link/>
            <yt-link onClick={()=>{window.open("https://www.youtube.com/channel/UCsF0wvE5345uRzW1dJqJTdw", "_blank")}}/>
        </FooterContainerSmView>)
    }

}
export default FooterContainerSmController