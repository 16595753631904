import React from "react";
import FolioView from "../views/FolioView";

class FolioController extends React.Component{
    state = {}
    render(){
        return(
        <FolioView>
            <hero-folio-button onClick={()=>{
                window.open("https://foliok12.com")
            }}/>
            <header-contact-link onClick={()=>{
                window.location.href="/contact-us";
            }}/>
            <pricing-button onClick={()=>{
                window.open("https://shop.pssk12.com/products/folio", "_blank")
            }}/>
            <pricing-contact-link onClick={()=>{
                window.location.href="/contact-us";
            }}/>
        </FolioView>)
    }

}
export default FolioController