import React from "react";
import IndexView from "../views/IndexView";

class IndexController extends React.Component{
    state = {}
    render(){
        return(
            <IndexView>
                <hero-quote-button onClick={()=>{
                    window.open("https://quotes.pssk12.com/create-quote", "_blank");
                }}/>
                <hero-about-button onClick={()=>{
                    const el = document.getElementById("about-us-section");
                    el.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
                }}/>
                <pm-learn-button onClick={()=>{
                    window.location.pathname = "/poster-maker";
                }}/>
                <pm-quote-button onClick={()=>{
                    window.open("https://quotes.pssk12.com", "_blank");
                }}/>
                <pm-instant-quote-link onClick={()=>{
                    window.open("https://quotes.pssk12.com/create-quote","_blank")
                }}/>
                <folio-try-button onClick={()=>{
                    window.open("https://foliok12.com", "_blank")
                }}/>
                <folio-learn-button onClick={()=>{
                    window.location.pathname="/folio"
                }}/>
            
            </IndexView>
        )
    }

}
export default IndexController