/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ContactComponentView from './ContactComponentView'
import NavComponentView from './NavComponentView'
import FooterComponentView from './FooterComponentView'
import FooterContainerSmView from './FooterContainerSmView'
import MobileFooterView from './MobileFooterView'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=644ad0ca9dae77a391ba11c2").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class ContactUsView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/ContactUsController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = ContactUsView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '645a68f33cfcc0fb9a8d3f15'
    htmlEl.dataset['wfSite'] = '644ad0ca9dae77a391ba11c2'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = ContactUsView.Controller !== ContactUsView ? transformProxies(this.props.children) : {
      'contact-phone': [],
      'contact-email': [],
      'contact-form-container': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-testing.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <ContactComponentView.Controller />
            <NavComponentView.Controller />
            <div className="af-class-section-13">
              <div className="af-class-container-28 w-container">
                <div className="af-class-columns-6 w-row">
                  <div className="af-class-column-71 w-col w-col-6">
                    <div className="af-class-upper-case-label">WE ARE HERE TO HELP!</div>
                    <h1 className="af-class-heading-10">Contact Us 👋 </h1>
                    <p className="af-class-p4">We would love to hear from you!<br />Have a question, comment, or suggestion?<br /><br />Fill out the form and a member of our team will get back to you shortly!<br /><br />Interested in your school becoming part of folio? <br />Fill out this form</p>
                    <div className="af-class-div-block-55" />
                    <div af className="af-class-p4"><strong className="af-class-bold-text-8">Phone: </strong>
                      {map(proxies['contact-phone'], props => <a href="tel:+18004859722" {...{...props, className: `af-class-link-4 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>1-800-485-9722</React.Fragment>}</a>)}<br /><strong className="af-class-bold-text-7">Email: </strong>
                      {map(proxies['contact-email'], props => <a href="mailto:info@pssk12.com" {...{...props, className: `af-class-link-5 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>info@pssk12.com</React.Fragment>}</a>)}<br /><br />PO Box 3872 Mooresville, NC 28117<br /><br />‍
                    </div>
                  </div>
                  {map(proxies['contact-form-container'], props => <div {...{...props, className: `af-class-column-82 w-col w-col-6 ${props.className || ''}`}}>{props.children}</div>)}
                </div>
              </div>
            </div>
            <FooterComponentView.Controller />
            <FooterContainerSmView.Controller />
            <MobileFooterView.Controller />
          </div>
        </span>
      </span>
    )
  }
}

export default ContactUsView

/* eslint-enable */