/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class FooterComponentView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/FooterComponentController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = FooterComponentView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    /* View has no WebFlow data attributes */

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    const proxies = FooterComponentView.Controller !== FooterComponentView ? transformProxies(this.props.children) : {
      'logo-button': [],
      'phone-link': [],
      'email-link': [],
      'pm-link': [],
      'folio-link': [],
      'shop-link': [],
      'quote-link': [],
      'contact-link': [],
      'help-link': [],
      'yt-link': [],
      'fb-link': [],
      'li-link': [],
      'ig-link': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/pss-testing.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-section-23">
            <div className="af-class-container-29 w-container">
              <div className="w-layout-grid af-class-footer-grid">
                <div id="w-node-_3b532271-6dc8-0281-bfcc-966913bdf494-13bdf491" className="af-class-footer-logo-block">
                  {map(proxies['logo-button'], props => <a data-ix="logo" href="#" {...{...props, className: `af-class-footer-logo w-nav-brand ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/NEW-pss-logo.svg" width={103} alt="PSS Logo" /></React.Fragment>}</a>)}
                  <p className="af-class-p6">PSS exists to help educators achieve their goals using amazing communication tools.<br /></p>
                  {map(proxies['phone-link'], props => <div {...{...props, className: `af-class-h10 af-class-footer-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>1-800-485-9722</React.Fragment>}</div>)}
                  {map(proxies['email-link'], props => <div {...{...props, className: `af-class-h10 af-class-footer-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>info@pssk12.com</React.Fragment>}</div>)}
                </div>
                <div id="w-node-_3b532271-6dc8-0281-bfcc-966913bdf49e-13bdf491" className="af-class-footer-links-container af-class-nav">
                  <h5 className="af-class-footer-header">Learn more</h5>
                  {map(proxies['pm-link'], props => <a href="#" {...{...props, className: `af-class-footer-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Poster Maker</React.Fragment>}</a>)}
                  {map(proxies['folio-link'], props => <a href="#" {...{...props, className: `af-class-footer-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Folio</React.Fragment>}</a>)}
                  {map(proxies['shop-link'], props => <a href="#" {...{...props, className: `af-class-footer-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Shop for Supplies</React.Fragment>}</a>)}
                  {map(proxies['quote-link'], props => <a href="#" {...{...props, className: `af-class-footer-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Get a Quote</React.Fragment>}</a>)}
                  {map(proxies['contact-link'], props => <a href="#" {...{...props, className: `af-class-footer-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Contact Us</React.Fragment>}</a>)}
                  {map(proxies['help-link'], props => <a href="#" {...{...props, className: `af-class-footer-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Product Help</React.Fragment>}</a>)}
                </div>
                <div className="af-class-footer-links-container">
                  <h5 className="af-class-footer-header">Find PSS</h5>
                  {map(proxies['yt-link'], props => <a href="#" {...{...props, className: `af-class-footer-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>YouTube</React.Fragment>}</a>)}
                  {map(proxies['fb-link'], props => <a href="#" {...{...props, className: `af-class-footer-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Facebook</React.Fragment>}</a>)}
                  {map(proxies['li-link'], props => <a href="#" {...{...props, className: `af-class-footer-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>LinkedIn</React.Fragment>}</a>)}
                  {map(proxies['ig-link'], props => <a href="#" {...{...props, className: `af-class-footer-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Instagram</React.Fragment>}</a>)}
                </div>
              </div>
              <div className="af-class-p6">©PSS. All rights reserved. &nbsp;PO Box 3872 Mooresville, NC 28117</div>
            </div>
          </div>
        </span>
      </span>
    )
  }
}

export default FooterComponentView